import React, { useState } from 'react';
import { 
  Building2, 
  Upload, 
  CheckCircle2, 
  ArrowLeft,
  Loader2,
  FileText,
  GraduationCap,
  Scale,
  UserCheck
} from 'lucide-react';
import { Link } from 'react-router-dom';

type FormData = {
  personalInfo: {
    name: string;
    dni: string;
    cuit: string;
    address: string;
    phone: string;
    email: string;
    birthDate: string;
  };
  professionalInfo: {
    title?: string;
    university?: string;
    graduationYear?: string;
    registrationNumber?: string;
  };
  documents: {
    [key: string]: File | null;
  };
  declarations: {
    noInhabilitacion: boolean;
    noAntecedentes: boolean;
    veracityData: boolean;
    acceptTerms: boolean;
  };
  office: {
    address: string;
    phone: string;
    schedule: string;
  };
};

export default function AdminRegistration() {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    personalInfo: {
      name: '',
      dni: '',
      cuit: '',
      address: '',
      phone: '',
      email: '',
      birthDate: '',
    },
    professionalInfo: {
      title: '',
      university: '',
      graduationYear: '',
      registrationNumber: '',
    },
    documents: {
      dni: null,
      cuit: null,
      titulo: null,
      certificadoAntecedentes: null,
      certificadoDeudores: null,
      constanciaAfip: null,
      certificadoCurso: null,
      seguroRC: null,
    },
    declarations: {
      noInhabilitacion: false,
      noAntecedentes: false,
      veracityData: false,
      acceptTerms: false,
    },
    office: {
      address: '',
      phone: '',
      schedule: '',
    },
  });

  const handleInputChange = (
    section: keyof FormData,
    field: string,
    value: string
  ) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, documentType: string) => {
    const file = e.target.files?.[0] || null;
    setFormData(prev => ({
      ...prev,
      documents: { ...prev.documents, [documentType]: file }
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    setIsSubmitting(false);
    // Navigate to success page or show success message
  };

  const renderStep1 = () => (
    <div className="space-y-6">
      <h3 className="text-lg font-medium flex items-center gap-2">
        <UserCheck className="h-5 w-5 text-blue-500" />
        Información Personal
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Nombre y Apellido
          </label>
          <input
            type="text"
            value={formData.personalInfo.name}
            onChange={(e) => handleInputChange('personalInfo', 'name', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            DNI
          </label>
          <input
            type="text"
            value={formData.personalInfo.dni}
            onChange={(e) => handleInputChange('personalInfo', 'dni', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            CUIT
          </label>
          <input
            type="text"
            value={formData.personalInfo.cuit}
            onChange={(e) => handleInputChange('personalInfo', 'cuit', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Fecha de Nacimiento
          </label>
          <input
            type="date"
            value={formData.personalInfo.birthDate}
            onChange={(e) => handleInputChange('personalInfo', 'birthDate', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Domicilio Real
          </label>
          <input
            type="text"
            value={formData.personalInfo.address}
            onChange={(e) => handleInputChange('personalInfo', 'address', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Teléfono
          </label>
          <input
            type="tel"
            value={formData.personalInfo.phone}
            onChange={(e) => handleInputChange('personalInfo', 'phone', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Correo Electrónico
          </label>
          <input
            type="email"
            value={formData.personalInfo.email}
            onChange={(e) => handleInputChange('personalInfo', 'email', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-6">
      <h3 className="text-lg font-medium flex items-center gap-2">
        <GraduationCap className="h-5 w-5 text-blue-500" />
        Información Profesional
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Título Profesional (si posee)
          </label>
          <input
            type="text"
            value={formData.professionalInfo.title}
            onChange={(e) => handleInputChange('professionalInfo', 'title', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Universidad
          </label>
          <input
            type="text"
            value={formData.professionalInfo.university}
            onChange={(e) => handleInputChange('professionalInfo', 'university', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Año de Graduación
          </label>
          <input
            type="text"
            value={formData.professionalInfo.graduationYear}
            onChange={(e) => handleInputChange('professionalInfo', 'graduationYear', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Número de Matrícula
          </label>
          <input
            type="text"
            value={formData.professionalInfo.registrationNumber}
            onChange={(e) => handleInputChange('professionalInfo', 'registrationNumber', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="space-y-6">
      <h3 className="text-lg font-medium flex items-center gap-2">
        <FileText className="h-5 w-5 text-blue-500" />
        Documentación Requerida
      </h3>
      <div className="space-y-4">
        {[
          { id: 'dni', label: 'DNI (frente y dorso)' },
          { id: 'cuit', label: 'Constancia de CUIT' },
          { id: 'titulo', label: 'Título Profesional (si corresponde)' },
          { id: 'certificadoAntecedentes', label: 'Certificado de Antecedentes Penales' },
          { id: 'certificadoDeudores', label: 'Certificado del Registro de Deudores Alimentarios' },
          { id: 'constanciaAfip', label: 'Constancia de Inscripción en AFIP' },
          { id: 'certificadoCurso', label: 'Certificado del Curso de Administrador' },
          { id: 'seguroRC', label: 'Seguro de Responsabilidad Civil' }
        ].map(doc => (
          <div key={doc.id} className="border rounded-lg p-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {doc.label}
            </label>
            <div className="flex items-center gap-4">
              <input
                type="file"
                onChange={(e) => handleFileChange(e, doc.id)}
                className="hidden"
                id={doc.id}
                accept=".pdf,.jpg,.jpeg,.png"
              />
              <label
                htmlFor={doc.id}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer flex items-center gap-2"
              >
                <Upload className="h-4 w-4" />
                Subir archivo
              </label>
              {formData.documents[doc.id] && (
                <span className="text-sm text-green-600 flex items-center gap-1">
                  <CheckCircle2 className="h-4 w-4" />
                  {formData.documents[doc.id]?.name}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderStep4 = () => (
    <div className="space-y-6">
      <h3 className="text-lg font-medium flex items-center gap-2">
        <Building2 className="h-5 w-5 text-blue-500" />
        Oficina de Atención
      </h3>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Dirección de la Oficina
          </label>
          <input
            type="text"
            value={formData.office.address}
            onChange={(e) => handleInputChange('office', 'address', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Teléfono de la Oficina
          </label>
          <input
            type="tel"
            value={formData.office.phone}
            onChange={(e) => handleInputChange('office', 'phone', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Horario de Atención
          </label>
          <input
            type="text"
            value={formData.office.schedule}
            onChange={(e) => handleInputChange('office', 'schedule', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Ej: Lunes a Viernes de 9 a 18hs"
            required
          />
        </div>
      </div>
    </div>
  );

  const renderStep5 = () => (
    <div className="space-y-6">
      <h3 className="text-lg font-medium flex items-center gap-2">
        <Scale className="h-5 w-5 text-blue-500" />
        Declaraciones Juradas
      </h3>
      <div className="space-y-4">
        <div className="border rounded-lg p-4">
          <label className="flex items-start gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={formData.declarations.noInhabilitacion}
              onChange={(e) => handleInputChange('declarations', 'noInhabilitacion', String(e.target.checked))}
              className="mt-1"
            />
            <span className="text-sm text-gray-700">
              Declaro bajo juramento no estar inhabilitado para ejercer el comercio ni la administración de consorcios
            </span>
          </label>
        </div>
        <div className="border rounded-lg p-4">
          <label className="flex items-start gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={formData.declarations.noAntecedentes}
              onChange={(e) => handleInputChange('declarations', 'noAntecedentes', String(e.target.checked))}
              className="mt-1"
            />
            <span className="text-sm text-gray-700">
              Declaro bajo juramento no poseer antecedentes penales
            </span>
          </label>
        </div>
        <div className="border rounded-lg p-4">
          <label className="flex items-start gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={formData.declarations.veracityData}
              onChange={(e) => handleInputChange('declarations', 'veracityData', String(e.target.checked))}
              className="mt-1"
            />
            <span className="text-sm text-gray-700">
              Declaro que los datos consignados son veraces y exactos
            </span>
          </label>
        </div>
        <div className="border rounded-lg p-4">
          <label className="flex items-start gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={formData.declarations.acceptTerms}
              onChange={(e) => handleInputChange('declarations', 'acceptTerms', String(e.target.checked))}
              className="mt-1"
            />
            <span className="text-sm text-gray-700">
              Acepto los términos y condiciones del Registro Público de Administradores
            </span>
          </label>
        </div>
      </div>
    </div>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      case 4:
        return renderStep4();
      case 5:
        return renderStep5();
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4">
        <div className="mb-8">
          <Link to="/" className="text-blue-600 hover:text-blue-700 flex items-center gap-1">
            <ArrowLeft className="h-4 w-4" />
            Volver al inicio
          </Link>
        </div>

        <div className="bg-white rounded-lg shadow-sm border p-6">
          <div className="flex items-center gap-3 mb-8">
            <Building2 className="h-6 w-6 text-blue-600" />
            <h1 className="text-2xl font-bold">Registro de Administrador</h1>
          </div>

          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={`w-full h-2 ${
                    index + 1 <= currentStep ? 'bg-blue-600' : 'bg-gray-200'
                  } ${index === 0 ? 'rounded-l-full' : ''} ${
                    index === 4 ? 'rounded-r-full' : ''
                  }`}
                />
              ))}
            </div>
            <div className="flex justify-between text-sm text-gray-600">
              <span>Información Personal</span>
              <span>Información Profesional</span>
              <span>Documentación</span>
              <span>Oficina</span>
              <span>Declaraciones</span>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {renderStepContent()}

            <div className="flex justify-between mt-8">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep(prev => prev - 1)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Anterior
                </button>
              )}
              {currentStep < 5 ? (
                <button
                  type="button"
                  onClick={() => setCurrentStep(prev => prev + 1)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors ml-auto"
                >
                  Siguiente
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 ml-auto"
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      Procesando...
                    </>
                  ) : (
                    'Completar Registro'
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}