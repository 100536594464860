import React from 'react';
import { Building2, Users, Receipt, Calendar } from 'lucide-react';

const stats = [
  {
    title: 'Total Edificios',
    value: '12',
    change: '↑ 4.5% vs mes anterior',
    changeColor: 'text-green-500',
    icon: Building2,
    iconColor: 'text-blue-500'
  },
  {
    title: 'Total Residentes',
    value: '342',
    change: '↑ 2.1% vs mes anterior',
    changeColor: 'text-green-500',
    icon: Users,
    iconColor: 'text-purple-500'
  },
  {
    title: 'Pagos Pendientes',
    value: '$24,500',
    change: '↓ 1.2% vs mes anterior',
    changeColor: 'text-red-500',
    icon: Receipt,
    iconColor: 'text-orange-500'
  },
  {
    title: 'Eventos Próximos',
    value: '8',
    change: 'Esta semana',
    changeColor: 'text-gray-500',
    icon: Calendar,
    iconColor: 'text-green-500'
  }
];

export default function StatsCards() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {stats.map((stat, index) => {
        const Icon = stat.icon;
        return (
          <div key={index} className="bg-white rounded-lg p-6 shadow-sm border">
            <div className="flex items-center justify-between">
              <h3 className="text-gray-500 text-sm">{stat.title}</h3>
              <Icon className={`h-6 w-6 ${stat.iconColor}`} />
            </div>
            <p className="text-2xl font-semibold mt-2">{stat.value}</p>
            <p className={`text-sm ${stat.changeColor} mt-2`}>{stat.change}</p>
          </div>
        );
      })}
    </div>
  );
}