import React from 'react';
import { AlertCircle } from 'lucide-react';

export default function DevBanner() {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-50 bg-yellow-50 border-b border-yellow-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3">
          <div className="flex items-center justify-center gap-2">
            <AlertCircle className="h-5 w-5 text-yellow-600" />
            <p className="text-sm text-yellow-700">
              Este sitio se encuentra en desarrollo. Algunas funcionalidades pueden no estar disponibles o ser meramente ilustrativas.
            </p>
          </div>
        </div>
      </div>
      {/* Spacer div to prevent content from hiding behind the fixed banner */}
      <div className="h-12"></div>
    </>
  );
}