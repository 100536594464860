import React from 'react';
import { Building2 } from 'lucide-react';

type Task = {
  id: number;
  name: string;
  status: 'pendiente' | 'en-progreso' | 'completada';
  dueDate: string;
  consortium: {
    id: number;
    name: string;
  };
};

const tasks: Task[] = [
  { 
    id: 1, 
    name: 'Mantenimiento Ascensor', 
    status: 'pendiente', 
    dueDate: '2024-03-25',
    consortium: { id: 1, name: 'Edificio San Martín' }
  },
  { 
    id: 2, 
    name: 'Inspección Seguridad', 
    status: 'en-progreso', 
    dueDate: '2024-03-28',
    consortium: { id: 2, name: 'Torre Libertad' }
  },
  { 
    id: 3, 
    name: 'Reunión de Consorcio', 
    status: 'completada', 
    dueDate: '2024-03-30',
    consortium: { id: 1, name: 'Edificio San Martín' }
  },
  { 
    id: 4, 
    name: 'Reparación Terraza', 
    status: 'pendiente', 
    dueDate: '2024-04-02',
    consortium: { id: 3, name: 'Residencial del Sol' }
  },
  { 
    id: 5, 
    name: 'Actualización Sistema', 
    status: 'en-progreso', 
    dueDate: '2024-04-05',
    consortium: { id: 2, name: 'Torre Libertad' }
  },
];

const getStatusBadgeClass = (status: Task['status']) => {
  const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium';
  switch (status) {
    case 'pendiente':
      return `${baseClasses} bg-yellow-100 text-yellow-800`;
    case 'en-progreso':
      return `${baseClasses} bg-blue-100 text-blue-800`;
    case 'completada':
      return `${baseClasses} bg-green-100 text-green-800`;
    default:
      return baseClasses;
  }
};

const getStatusLabel = (status: Task['status']) => {
  const labels = {
    'pendiente': 'Pendiente',
    'en-progreso': 'En Progreso',
    'completada': 'Completada'
  };
  return labels[status];
};

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export default function TasksTable() {
  return (
    <div className="bg-white rounded-lg shadow-sm border">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Tareas Pendientes</h2>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700 transition-colors">
            Nueva Tarea
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Nombre</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Consorcio</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Estado</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Fecha Límite</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task) => (
                <tr key={task.id} className="border-b last:border-b-0 hover:bg-gray-50">
                  <td className="py-3 px-4">
                    <span className="text-sm font-medium text-gray-700">{task.name}</span>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Building2 className="h-4 w-4 text-blue-500" />
                      <span className="text-sm text-gray-600">{task.consortium.name}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <span className={getStatusBadgeClass(task.status)}>
                      {getStatusLabel(task.status)}
                    </span>
                  </td>
                  <td className="py-3 px-4">
                    <span className="text-sm text-gray-600">{formatDate(task.dueDate)}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}