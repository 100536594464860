import React, { useState, useEffect } from 'react';
import { 
  Building2, 
  Users, 
  Briefcase, 
  ArrowRight,
  LayoutDashboard,
  LineChart,
  Zap,
  Loader2,
  Bot,
  AlertCircle,
  Heart
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import AIChat from '../components/AIChat';

type UserRole = 'admin' | 'owner' | 'provider' | 'other';

export default function LandingPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState<UserRole>('owner');
  const [customRole, setCustomRole] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState<string | null>(null);

  // Reset scroll position when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mainCards = [
    {
      title: "Acceso a Administradores",
      description: "Portal exclusivo para la gestión administrativa de consorcios. Accede a todas las herramientas necesarias para una gestión eficiente y transparente.",
      icon: Building2,
      iconBg: "bg-blue-50",
      iconColor: "text-blue-600",
      buttonBg: "bg-blue-600",
      buttonHover: "hover:bg-blue-700",
      link: "/admin"
    },
    {
      title: "Acceso a Propietarios",
      description: "Consulta de expensas, documentación y comunicaciones. Mantente informado sobre todo lo relacionado con tu propiedad y el consorcio.",
      icon: Users,
      iconBg: "bg-green-50",
      iconColor: "text-green-600",
      buttonBg: "bg-green-600",
      buttonHover: "hover:bg-green-700",
      link: "/propietarios"
    },
    {
      title: "Acceso a Proveedores",
      description: "Registro y gestión de servicios para consorcios",
      icon: Briefcase,
      iconBg: "bg-purple-50",
      iconColor: "text-purple-600",
      buttonBg: "bg-purple-600",
      buttonHover: "hover:bg-purple-700",
      link: "/proveedores"
    }
  ];

  const features = [
    {
      title: "Comunidad Armoniosa",
      description: "Fomentamos la colaboración y el diálogo constructivo entre residentes, administradores y proveedores",
      icon: Heart,
      iconColor: "text-rose-500"
    },
    {
      title: "Transparencia Total",
      description: "Acceso a información actualizada y reportes detallados",
      icon: LineChart,
      iconColor: "text-purple-500"
    },
    {
      title: "Gestión Eficiente",
      description: "Administración completa de gastos, mantenimiento y documentación",
      icon: LayoutDashboard,
      iconColor: "text-blue-500"
    },
    {
      title: "Automatización de Tareas",
      description: "Generación automática de reportes, recordatorios y gestión de asambleas virtuales",
      icon: Zap,
      iconColor: "text-yellow-500"
    }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email.trim() || !name.trim() || (role === 'other' && !customRole.trim())) return;
    
    setIsSubmitting(true);
    setSubscriptionError(null);
    
    try {
      const { error } = await supabase
        .from('email_leads')
        .insert([{ 
          email: email.trim(),
          name: name.trim(),
          role: role === 'other' ? 'custom' : role,
          custom_role: role === 'other' ? customRole.trim() : null
        }]);

      if (error) {
        if (error.code === '23505') { // Unique violation
          setSubscriptionError('Este email ya está suscrito a nuestro newsletter.');
        } else {
          throw error;
        }
      } else {
        setEmail('');
        setName('');
        setCustomRole('');
        setSubscribed(true);
      }
    } catch (error) {
      console.error('Error al suscribirse:', error);
      setSubscriptionError('Error al procesar la suscripción. Por favor, intente nuevamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
        <div className="text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
            Consorcio
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-12">
            Gestión integral de consorcios para administradores, propietarios y proveedores
          </p>
        </div>

        {/* Main Cards */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {mainCards.map((card, index) => {
            const Icon = card.icon;
            return (
              <div key={index} className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 hover:shadow-md transition-shadow flex flex-col">
                <div className={`${card.iconBg} rounded-full w-12 h-12 flex items-center justify-center mb-4`}>
                  <Icon className={`h-6 w-6 ${card.iconColor}`} />
                </div>
                <h2 className="text-xl font-semibold text-gray-900 mb-3">
                  {card.title}
                </h2>
                <p className="text-gray-600 mb-6 text-sm flex-grow">
                  {card.description}
                </p>
                <Link 
                  to={card.link}
                  className={`w-full flex items-center justify-center gap-2 px-4 py-2 ${card.buttonBg} text-white rounded-lg ${card.buttonHover} transition-colors mt-auto`}
                >
                  Ingresar
                  <ArrowRight className="h-4 w-4" />
                </Link>
              </div>
            );
          })}
        </div>

        {/* Features Section */}
        <div className="mt-20 text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-12">
            Características Principales
          </h2>
          <div className="grid md:grid-cols-4 gap-8 max-w-6xl mx-auto">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="flex flex-col items-center p-6 bg-white rounded-lg shadow-sm border hover:shadow-md transition-shadow">
                  <div className={`rounded-full p-3 ${feature.iconColor} bg-opacity-10 mb-4`}>
                    <Icon className={`h-6 w-6 ${feature.iconColor}`} />
                  </div>
                  <h3 className="font-medium text-gray-900 mb-2">{feature.title}</h3>
                  <p className="text-gray-600 text-sm text-center">{feature.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* AI Assistant Section */}
        <div className="mt-20">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900 mb-4 flex items-center justify-center gap-2">
                <Bot className="h-8 w-8 text-blue-500" />
                Asistente Virtual
              </h2>
              <p className="text-lg text-gray-600 mb-4">
                Tu experto en gestión de consorcios disponible 24/7
              </p>
              <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 max-w-2xl mx-auto">
                <div className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-blue-600 mt-0.5 shrink-0" />
                  <p className="text-sm text-blue-800">
                    Este asistente virtual es de acceso libre y gratuito. Puedes realizar consultas sobre legislación, gestión administrativa, y mejores prácticas en la administración de consorcios. Tené en cuenta que se encuentra en desarrollo, y puede brindar respuestas equivocadas o incompletas durante sus primeras etapas de aprendizaje.
                  </p>
                </div>
              </div>
            </div>
            <AIChat />
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="mt-20 max-w-2xl mx-auto text-center">
          <div className="bg-gradient-to-r from-blue-500 to-indigo-600 rounded-xl shadow-lg p-8 text-white">
            <h2 className="text-2xl font-bold mb-4">
              Mantenete Informado
            </h2>
            <p className="text-blue-100 mb-6">
              Suscríbete para recibir un <b>beneficio especial</b> durante el lanzamiento.
            </p>
            {!subscribed ? (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex flex-col gap-3 max-w-md mx-auto">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre o Empresa"
                    className="px-4 py-2 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    required
                  />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Tu correo electrónico"
                    className="px-4 py-2 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    required
                  />
                  <div className="flex flex-col gap-1">
                    <label className="text-sm text-blue-100 text-left">
                      <b>Soy:</b>
                    </label>
                    <select
                      value={role}
                      onChange={(e) => setRole(e.target.value as UserRole)}
                      className="px-4 py-2 rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      <option value="owner">Propietario</option>
                      <option value="admin">Administrador</option>
                      <option value="provider">Proveedor</option>
                      <option value="other">Otro</option>
                    </select>
                  </div>
                  {role === 'other' && (
                    <input
                      type="text"
                      value={customRole}
                      onChange={(e) => setCustomRole(e.target.value)}
                      placeholder="Especifica tu rol"
                      className="px-4 py-2 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
                      required
                    />
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-6 py-2 bg-white text-blue-600 rounded-lg font-medium hover:bg-blue-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin" />
                        Procesando...
                      </>
                    ) : (
                      'Suscribirse'
                    )}
                  </button>
                </div>
                {subscriptionError && (
                  <p className="text-sm bg-red-400 text-white p-2 rounded-lg">
                    {subscriptionError}
                  </p>
                )}
              </form>
            ) : (
              <div className="bg-white/10 rounded-lg p-4 max-w-md mx-auto">
                <p className="text-white font-medium">
                  ¡Gracias por suscribirte! Te mantendremos informado sobre todas las novedades.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-50 border-t mt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-center text-gray-600 text-sm">
            © {new Date().getFullYear()} Consorcio.com.ar | Todos los derechos reservados.
          </div>
        </div>
      </footer>
    </div>
  );
}