import React, { useState } from 'react';
import { FileText, Scale, Book, Pencil, Save, X } from 'lucide-react';

type SettingSection = {
  id: string;
  title: string;
  description: string;
  link: string;
  icon: React.ElementType;
};

export default function Settings() {
  const [settings, setSettings] = useState<SettingSection[]>([
    {
      id: 'license',
      title: 'Matrícula del Administrador',
      description: 'Número de matrícula y documentación profesional',
      link: 'https://ejemplo.com/matricula-123',
      icon: FileText
    },
    {
      id: 'legislation',
      title: 'Legislación',
      description: 'Marco legal y normativas aplicables',
      link: 'https://ejemplo.com/legislacion',
      icon: Scale
    },
    {
      id: 'regulations',
      title: 'Reglamento de Copropiedad',
      description: 'Normas y disposiciones del consorcio',
      link: 'https://ejemplo.com/reglamento',
      icon: Book
    }
  ]);

  const [editingId, setEditingId] = useState<string | null>(null);
  const [editValue, setEditValue] = useState('');

  const handleEdit = (section: SettingSection) => {
    setEditingId(section.id);
    setEditValue(section.link);
  };

  const handleSave = (id: string) => {
    setSettings(settings.map(section =>
      section.id === id ? { ...section, link: editValue } : section
    ));
    setEditingId(null);
    setEditValue('');
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditValue('');
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border">
      <div className="p-6">
        <h2 className="text-lg font-semibold mb-6">Configuración</h2>
        
        <div className="space-y-6">
          {settings.map((section) => {
            const Icon = section.icon;
            return (
              <div key={section.id} className="border rounded-lg p-4">
                <div className="flex items-start justify-between">
                  <div className="flex items-start gap-3">
                    <div className="mt-1">
                      <Icon className="h-5 w-5 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">{section.title}</h3>
                      <p className="text-sm text-gray-500 mt-1">{section.description}</p>
                      
                      {editingId === section.id ? (
                        <div className="mt-2 flex items-center gap-2">
                          <input
                            type="url"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            className="flex-1 px-3 py-1.5 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Ingrese el enlace..."
                          />
                          <button
                            onClick={() => handleSave(section.id)}
                            className="p-1.5 text-green-600 hover:text-green-700"
                            title="Guardar"
                          >
                            <Save className="h-4 w-4" />
                          </button>
                          <button
                            onClick={handleCancel}
                            className="p-1.5 text-gray-400 hover:text-gray-500"
                            title="Cancelar"
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      ) : (
                        <div className="mt-2 flex items-center gap-2">
                          <a
                            href={section.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm text-blue-600 hover:text-blue-700 hover:underline"
                          >
                            {section.link}
                          </a>
                          <button
                            onClick={() => handleEdit(section)}
                            className="p-1.5 text-gray-400 hover:text-gray-500"
                            title="Editar"
                          >
                            <Pencil className="h-4 w-4" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}