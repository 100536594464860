import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Video, Calendar, Clock, List, X, Building2, CheckCircle2 } from 'lucide-react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

type Assembly = {
  id: number;
  date: string;
  time: string;
  topics: string;
  meetingUrl: string;
  consortium: {
    id: number;
    name: string;
  };
  status: 'programada' | 'en-curso' | 'finalizada';
  results?: string;
};

const consortiums = [
  { id: 1, name: 'Edificio San Martín' },
  { id: 2, name: 'Torre Libertad' },
  { id: 3, name: 'Residencial del Sol' },
  { id: 4, name: 'Plaza Central' },
];

export default function VirtualAssembly() {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [topics, setTopics] = useState('');
  const [selectedConsortium, setSelectedConsortium] = useState('');
  const [assemblies, setAssemblies] = useState<Assembly[]>([]);
  const [isEditingResults, setIsEditingResults] = useState<number | null>(null);
  const [results, setResults] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const consortium = consortiums.find(c => c.id === parseInt(selectedConsortium));
    if (!consortium) return;

    const newAssembly: Assembly = {
      id: Date.now(),
      date,
      time,
      topics,
      meetingUrl: `https://meet.google.com/${Math.random().toString(36).substring(2, 15)}`,
      consortium,
      status: 'programada'
    };

    setAssemblies([...assemblies, newAssembly]);
    setIsOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setDate('');
    setTime('');
    setTopics('');
    setSelectedConsortium('');
  };

  const formatDate = (dateStr: string) => {
    return format(new Date(dateStr), "d 'de' MMMM, yyyy", { locale: es });
  };

  const getStatusBadgeClass = (status: Assembly['status']) => {
    const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium';
    switch (status) {
      case 'programada':
        return `${baseClasses} bg-yellow-100 text-yellow-800`;
      case 'en-curso':
        return `${baseClasses} bg-blue-100 text-blue-800`;
      case 'finalizada':
        return `${baseClasses} bg-green-100 text-green-800`;
      default:
        return baseClasses;
    }
  };

  const handleStatusChange = (assemblyId: number, newStatus: Assembly['status']) => {
    setAssemblies(assemblies.map(assembly => 
      assembly.id === assemblyId ? { ...assembly, status: newStatus } : assembly
    ));
  };

  const handleResultsSave = (assemblyId: number) => {
    setAssemblies(assemblies.map(assembly => 
      assembly.id === assemblyId ? { ...assembly, results, status: 'finalizada' } : assembly
    ));
    setIsEditingResults(null);
    setResults('');
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Asambleas Virtuales</h2>
          <button
            onClick={() => setIsOpen(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700 transition-colors flex items-center gap-2"
          >
            <Video className="h-4 w-4" />
            Nueva Asamblea Virtual
          </button>
        </div>

        {/* Tabla de Asambleas */}
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Fecha y Hora</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Consorcio</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Temas</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Estado</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {assemblies.map((assembly) => (
                <tr key={assembly.id} className="border-b last:border-b-0 hover:bg-gray-50">
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-gray-400" />
                      <span className="text-sm font-medium">{formatDate(assembly.date)}</span>
                      <Clock className="h-4 w-4 text-gray-400 ml-2" />
                      <span className="text-sm">{assembly.time}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Building2 className="h-4 w-4 text-blue-500" />
                      <span className="text-sm">{assembly.consortium.name}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <p className="text-sm text-gray-600 line-clamp-2">{assembly.topics}</p>
                  </td>
                  <td className="py-3 px-4">
                    <select
                      value={assembly.status}
                      onChange={(e) => handleStatusChange(assembly.id, e.target.value as Assembly['status'])}
                      className={getStatusBadgeClass(assembly.status)}
                    >
                      <option value="programada">Programada</option>
                      <option value="en-curso">En curso</option>
                      <option value="finalizada">Finalizada</option>
                    </select>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <a
                        href={assembly.meetingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-3 py-1 bg-green-100 text-green-700 rounded-full text-sm hover:bg-green-200 transition-colors flex items-center gap-1"
                      >
                        <Video className="h-3 w-3" />
                        Unirse
                      </a>
                      {assembly.status !== 'finalizada' ? (
                        <button
                          onClick={() => {
                            setIsEditingResults(assembly.id);
                            setResults(assembly.results || '');
                          }}
                          className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm hover:bg-blue-200 transition-colors"
                        >
                          Agregar Resultados
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setIsEditingResults(assembly.id);
                            setResults(assembly.results || '');
                          }}
                          className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm hover:bg-gray-200 transition-colors flex items-center gap-1"
                        >
                          <CheckCircle2 className="h-3 w-3" />
                          Ver Resultados
                        </button>
                      )}
                    </div>
                    {isEditingResults === assembly.id && (
                      <div className="mt-2">
                        <textarea
                          value={results}
                          onChange={(e) => setResults(e.target.value)}
                          className="w-full px-3 py-2 border rounded-lg text-sm"
                          rows={3}
                          placeholder="Ingrese los resultados de la asamblea..."
                        />
                        <div className="flex justify-end gap-2 mt-2">
                          <button
                            onClick={() => setIsEditingResults(null)}
                            className="px-3 py-1 text-sm text-gray-600"
                          >
                            Cancelar
                          </button>
                          <button
                            onClick={() => handleResultsSave(assembly.id)}
                            className="px-3 py-1 bg-blue-600 text-white rounded-lg text-sm"
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal de Nueva Asamblea */}
        <Transition show={isOpen} as={React.Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => setIsOpen(false)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
              </Transition.Child>

              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>

              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex justify-between items-center mb-4">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Nueva Asamblea Virtual
                    </Dialog.Title>
                    <button
                      onClick={() => setIsOpen(false)}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label htmlFor="consortium" className="block text-sm font-medium text-gray-700 mb-1">
                        Consorcio
                      </label>
                      <select
                        id="consortium"
                        required
                        value={selectedConsortium}
                        onChange={(e) => setSelectedConsortium(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="">Seleccione un consorcio</option>
                        {consortiums.map((consortium) => (
                          <option key={consortium.id} value={consortium.id}>
                            {consortium.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                        Fecha
                      </label>
                      <input
                        type="date"
                        id="date"
                        required
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>

                    <div>
                      <label htmlFor="time" className="block text-sm font-medium text-gray-700 mb-1">
                        Hora
                      </label>
                      <input
                        type="time"
                        id="time"
                        required
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>

                    <div>
                      <label htmlFor="topics" className="block text-sm font-medium text-gray-700 mb-1">
                        Temas a tratar
                      </label>
                      <textarea
                        id="topics"
                        required
                        value={topics}
                        onChange={(e) => setTopics(e.target.value)}
                        rows={3}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Ingrese los temas a tratar..."
                      />
                    </div>

                    <div className="flex justify-end gap-3 mt-6">
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700 transition-colors"
                      >
                        Crear Asamblea
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}