import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Building2, AlertCircle } from 'lucide-react';

type UserType = 'admin' | 'owner' | 'provider';

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();

  // Get user type from URL path
  const userType: UserType = location.pathname.includes('admin') 
    ? 'admin' 
    : location.pathname.includes('proveedores')
      ? 'provider'
      : 'owner';

  const handleContinue = () => {
    switch (userType) {
      case 'admin':
        navigate('/admin');
        break;
      case 'owner':
        navigate('/propietarios');
        break;
      case 'provider':
        navigate('/proveedores');
        break;
    }
  };

  const getTitle = () => {
    switch (userType) {
      case 'admin':
        return 'Administrador';
      case 'owner':
        return 'Propietario';
      case 'provider':
        return 'Proveedor';
    }
  };

  const getDemoData = () => {
    switch (userType) {
      case 'admin':
        return (
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>Acceso a panel de administración completo</li>
            <li>Gestión de múltiples edificios</li>
            <li>Herramientas de comunicación con propietarios</li>
            <li>Sistema de gestión de gastos y expensas</li>
          </ul>
        );
      case 'owner':
        return (
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>Visualización de expensas y pagos</li>
            <li>Acceso a documentos del consorcio</li>
            <li>Comunicación directa con administración</li>
            <li>Participación en asambleas virtuales</li>
          </ul>
        );
      case 'provider':
        return (
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>Portal de servicios y presupuestos</li>
            <li>Gestión de trabajos y mantenimientos</li>
            <li>Facturación y seguimiento de pagos</li>
            <li>Calendario de servicios programados</li>
          </ul>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <Building2 className="h-12 w-12 text-blue-600" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Acceso de {getTitle()}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
            <div className="flex items-start gap-2">
              <AlertCircle className="h-5 w-5 text-blue-600 mt-0.5" />
              <div>
                <h3 className="text-blue-800 font-medium mb-2">
                  Datos de Prueba
                </h3>
                <p className="text-sm text-blue-700 mb-4">
                  A continuación verá datos de ejemplo para demostrar las funcionalidades disponibles para {getTitle().toLowerCase()}es:
                </p>
                {getDemoData()}
              </div>
            </div>
          </div>

          <button
            onClick={handleContinue}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
}