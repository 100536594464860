import React, { useState } from 'react';
import { MessageSquare, Heart, Share2, Users, Calendar, AlertCircle } from 'lucide-react';

type Post = {
  id: number;
  author: string;
  content: string;
  timestamp: string;
  likes: number;
  comments: number;
  type: 'general' | 'event' | 'help' | 'initiative';
};

const samplePosts: Post[] = [
  {
    id: 1,
    author: 'María García',
    content: 'Organizamos una colecta de alimentos para el comedor del barrio. Los que quieran participar pueden dejar sus donaciones en la portería.',
    timestamp: '2024-03-15T14:30:00',
    likes: 15,
    comments: 5,
    type: 'initiative'
  },
  {
    id: 2,
    author: 'Carlos Rodríguez',
    content: '¿Alguien tiene una escalera que me pueda prestar por unas horas? Necesito cambiar unas lámparas.',
    timestamp: '2024-03-15T10:15:00',
    likes: 3,
    comments: 8,
    type: 'help'
  },
  {
    id: 3,
    author: 'Ana Martínez',
    content: 'Este sábado a las 17hs organizamos una merienda en el SUM para conocernos mejor entre vecinos. ¡Están todos invitados!',
    timestamp: '2024-03-14T18:45:00',
    likes: 24,
    comments: 12,
    type: 'event'
  }
];

export default function CommunityFeed() {
  const [posts, setPosts] = useState(samplePosts);
  const [newPost, setNewPost] = useState('');
  const [selectedType, setSelectedType] = useState<Post['type']>('general');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newPost.trim()) return;

    const post: Post = {
      id: Date.now(),
      author: 'Juan Pérez', // This would come from user context
      content: newPost.trim(),
      timestamp: new Date().toISOString(),
      likes: 0,
      comments: 0,
      type: selectedType
    };

    setPosts([post, ...posts]);
    setNewPost('');
    setSelectedType('general');
  };

  const getPostTypeStyles = (type: Post['type']) => {
    switch (type) {
      case 'event':
        return 'bg-purple-100 text-purple-800';
      case 'help':
        return 'bg-orange-100 text-orange-800';
      case 'initiative':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (timestamp: string) => {
    return new Date(timestamp).toLocaleDateString('es-ES', {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="space-y-6">
      {/* Community Guidelines */}
      <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
        <div className="flex items-start gap-3">
          <AlertCircle className="h-5 w-5 text-blue-600 mt-0.5" />
          <div>
            <h3 className="font-medium text-blue-900 mb-1">Pautas de Convivencia</h3>
            <p className="text-sm text-blue-800">
              Este espacio está diseñado para fomentar una comunidad armoniosa y colaborativa. 
              Valoramos el respeto mutuo, la empatía y la comunicación constructiva. 
              Juntos podemos crear un ambiente positivo que beneficie a todos los residentes. 
              En un futuro cercano, es posible que los mensajes sean filtrados o moderados por un agente de Inteligencia Artificial en caso de                 que el respeto y la armonía se encuentren en riesgo de ser vulnerados. 
            </p>
          </div>
        </div>
      </div>

      {/* Create Post */}
      <div className="bg-white rounded-lg shadow-sm border p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            value={newPost}
            onChange={(e) => setNewPost(e.target.value)}
            placeholder="Comparte algo con la comunidad..."
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={3}
          />
          <div className="flex items-center justify-between">
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value as Post['type'])}
              className="px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="general">General</option>
              <option value="event">Evento</option>
              <option value="help">Ayuda</option>
              <option value="initiative">Iniciativa</option>
            </select>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Publicar
            </button>
          </div>
        </form>
      </div>

      {/* Posts Feed */}
      <div className="space-y-4">
        {posts.map((post) => (
          <div key={post.id} className="bg-white rounded-lg shadow-sm border p-4">
            <div className="flex items-start justify-between mb-3">
              <div>
                <h3 className="font-medium">{post.author}</h3>
                <span className="text-sm text-gray-500">{formatDate(post.timestamp)}</span>
              </div>
              <span className={`px-2 py-1 rounded-full text-xs font-medium ${getPostTypeStyles(post.type)}`}>
                {post.type === 'event' && 'Evento'}
                {post.type === 'help' && 'Ayuda'}
                {post.type === 'initiative' && 'Iniciativa'}
                {post.type === 'general' && 'General'}
              </span>
            </div>
            <p className="text-gray-600 mb-4">{post.content}</p>
            <div className="flex items-center gap-6 text-gray-500">
              <button className="flex items-center gap-1 hover:text-blue-600">
                <Heart className="h-4 w-4" />
                <span className="text-sm">{post.likes}</span>
              </button>
              <button className="flex items-center gap-1 hover:text-blue-600">
                <MessageSquare className="h-4 w-4" />
                <span className="text-sm">{post.comments}</span>
              </button>
              <button className="flex items-center gap-1 hover:text-blue-600">
                <Share2 className="h-4 w-4" />
                <span className="text-sm">Compartir</span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}