import React, { useState } from 'react';
import { Building2, Plus, Search, Edit, Trash2, MapPin, Users, Phone, Mail } from 'lucide-react';

type Building = {
  id: number;
  name: string;
  address: string;
  units: number;
  residents: number;
  contact: {
    phone: string;
    email: string;
  };
};

const buildings: Building[] = [
  {
    id: 1,
    name: 'Edificio San Martín',
    address: 'San Martín 1234, CABA',
    units: 24,
    residents: 45,
    contact: {
      phone: '11-4567-8901',
      email: 'admin@edificiosanmartin.com'
    }
  },
  {
    id: 2,
    name: 'Torre Libertad',
    address: 'Av. Libertador 5678, CABA',
    units: 36,
    residents: 72,
    contact: {
      phone: '11-2345-6789',
      email: 'admin@torrelibertad.com'
    }
  }
];

export default function Buildings() {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredBuildings = buildings.filter(building => 
    building.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    building.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm border p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Gestión de Edificios</h2>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2">
            <Plus className="h-4 w-4" />
            Nuevo Edificio
          </button>
        </div>

        <div className="mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Buscar por nombre o dirección..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {filteredBuildings.map((building) => (
            <div key={building.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-start gap-3">
                  <div className="bg-blue-50 rounded-lg p-2">
                    <Building2 className="h-6 w-6 text-blue-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-lg">{building.name}</h3>
                    <p className="text-gray-600 flex items-center gap-1 text-sm">
                      <MapPin className="h-4 w-4" />
                      {building.address}
                    </p>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button className="p-1 text-gray-400 hover:text-gray-600">
                    <Edit className="h-4 w-4" />
                  </button>
                  <button className="p-1 text-gray-400 hover:text-red-600">
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="bg-gray-50 rounded-lg p-3">
                  <p className="text-sm text-gray-600">Unidades</p>
                  <p className="text-lg font-medium">{building.units}</p>
                </div>
                <div className="bg-gray-50 rounded-lg p-3">
                  <p className="text-sm text-gray-600">Residentes</p>
                  <div className="flex items-center gap-1">
                    <Users className="h-4 w-4 text-gray-400" />
                    <p className="text-lg font-medium">{building.residents}</p>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <p className="text-sm text-gray-600 flex items-center gap-1">
                  <Phone className="h-4 w-4" />
                  {building.contact.phone}
                </p>
                <p className="text-sm text-gray-600 flex items-center gap-1">
                  <Mail className="h-4 w-4" />
                  {building.contact.email}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}