import React, { useState } from 'react';
import { 
  Building2, 
  Upload, 
  CheckCircle2, 
  ArrowLeft,
  Loader2
} from 'lucide-react';
import { Link } from 'react-router-dom';

type FormData = {
  companyName: string;
  taxId: string;
  address: string;
  phone: string;
  email: string;
  services: string;
  specialization: string;
  experience: string;
  references: {
    name: string;
    contact: string;
  }[];
  documents: {
    [key: string]: File | null;
  };
  declarations: {
    noInhabilitacion: boolean;
    noAntecedentes: boolean;
  };
};

export default function ProviderRegistration() {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    taxId: '',
    address: '',
    phone: '',
    email: '',
    services: '',
    specialization: '',
    experience: '',
    references: [{ name: '', contact: '' }, { name: '', contact: '' }],
    documents: {
      contractSocial: null,
      certificadoVigencia: null,
      matriculaProfesional: null,
      antecedentes: null,
      afip: null,
      seguro: null
    },
    declarations: {
      noInhabilitacion: false,
      noAntecedentes: false
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, documentType: string) => {
    const file = e.target.files?.[0] || null;
    setFormData(prev => ({
      ...prev,
      documents: { ...prev.documents, [documentType]: file }
    }));
  };

  const handleReferenceChange = (index: number, field: 'name' | 'contact', value: string) => {
    const newReferences = [...formData.references];
    newReferences[index] = { ...newReferences[index], [field]: value };
    setFormData(prev => ({ ...prev, references: newReferences }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    setIsSubmitting(false);
    // Navigate to success page or show success message
  };

  const renderStep1 = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Información General</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Nombre o Razón Social
          </label>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            CUIT
          </label>
          <input
            type="text"
            name="taxId"
            value={formData.taxId}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Domicilio Legal
          </label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Teléfono
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Correo Electrónico
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Documentación Legal</h3>
      <div className="space-y-4">
        {[
          { id: 'contractSocial', label: 'Contrato Social' },
          { id: 'certificadoVigencia', label: 'Certificado de Vigencia' },
          { id: 'matriculaProfesional', label: 'Matrícula Profesional' }
        ].map(doc => (
          <div key={doc.id} className="border rounded-lg p-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {doc.label}
            </label>
            <div className="flex items-center gap-4">
              <input
                type="file"
                onChange={(e) => handleFileChange(e, doc.id)}
                className="hidden"
                id={doc.id}
                accept=".pdf,.doc,.docx"
              />
              <label
                htmlFor={doc.id}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer flex items-center gap-2"
              >
                <Upload className="h-4 w-4" />
                Subir archivo
              </label>
              {formData.documents[doc.id] && (
                <span className="text-sm text-green-600 flex items-center gap-1">
                  <CheckCircle2 className="h-4 w-4" />
                  {formData.documents[doc.id]?.name}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Servicios Ofrecidos</h3>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Descripción de Servicios
          </label>
          <textarea
            name="services"
            value={formData.services}
            onChange={handleInputChange}
            rows={4}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Áreas de Especialización
          </label>
          <textarea
            name="specialization"
            value={formData.specialization}
            onChange={handleInputChange}
            rows={3}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Experiencia Previa en Consorcios
          </label>
          <textarea
            name="experience"
            value={formData.experience}
            onChange={handleInputChange}
            rows={3}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
    </div>
  );

  const renderStep4 = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Referencias</h3>
      <div className="space-y-6">
        {formData.references.map((ref, index) => (
          <div key={index} className="border rounded-lg p-4">
            <h4 className="text-sm font-medium text-gray-700 mb-3">
              Referencia {index + 1}
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nombre
                </label>
                <input
                  type="text"
                  value={ref.name}
                  onChange={(e) => handleReferenceChange(index, 'name', e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Contacto
                </label>
                <input
                  type="text"
                  value={ref.contact}
                  onChange={(e) => handleReferenceChange(index, 'contact', e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderStep5 = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Documentación Adicional</h3>
      <div className="space-y-4">
        {[
          { id: 'antecedentes', label: 'Certificado de Antecedentes Penales' },
          { id: 'afip', label: 'Constancia de Inscripción en AFIP' },
          { id: 'seguro', label: 'Seguro de Responsabilidad Civil' }
        ].map(doc => (
          <div key={doc.id} className="border rounded-lg p-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {doc.label}
            </label>
            <div className="flex items-center gap-4">
              <input
                type="file"
                onChange={(e) => handleFileChange(e, doc.id)}
                className="hidden"
                id={doc.id}
                accept=".pdf,.doc,.docx"
              />
              <label
                htmlFor={doc.id}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer flex items-center gap-2"
              >
                <Upload className="h-4 w-4" />
                Subir archivo
              </label>
              {formData.documents[doc.id] && (
                <span className="text-sm text-green-600 flex items-center gap-1">
                  <CheckCircle2 className="h-4 w-4" />
                  {formData.documents[doc.id]?.name}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderStep6 = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Declaraciones Juradas</h3>
      <div className="space-y-4">
        <div className="border rounded-lg p-4">
          <label className="flex items-start gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={formData.declarations.noInhabilitacion}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                declarations: { ...prev.declarations, noInhabilitacion: e.target.checked }
              }))}
              className="mt-1"
            />
            <span className="text-sm text-gray-700">
              Declaro bajo juramento no estar inhabilitado para ejercer el comercio
            </span>
          </label>
        </div>
        <div className="border rounded-lg p-4">
          <label className="flex items-start gap-2 cursor-pointer">
            <input
              type="checkbox"
              checked={formData.declarations.noAntecedentes}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                declarations: { ...prev.declarations, noAntecedentes: e.target.checked }
              }))}
              className="mt-1"
            />
            <span className="text-sm text-gray-700">
              Declaro bajo juramento no poseer antecedentes penales relacionados con la administración de bienes o fondos ajenos
            </span>
          </label>
        </div>
      </div>
    </div>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      case 4:
        return renderStep4();
      case 5:
        return renderStep5();
      case 6:
        return renderStep6();
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4">
        <div className="mb-8">
          <Link to="/" className="text-blue-600 hover:text-blue-700 flex items-center gap-1">
            <ArrowLeft className="h-4 w-4" />
            Volver al inicio
          </Link>
        </div>

        <div className="bg-white rounded-lg shadow-sm border p-6">
          <div className="flex items-center gap-3 mb-8">
            <Building2 className="h-6 w-6 text-blue-600" />
            <h1 className="text-2xl font-bold">Registro de Proveedores</h1>
          </div>

          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              {Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className={`w-full h-2 ${
                    index + 1 <= currentStep ? 'bg-blue-600' : 'bg-gray-200'
                  } ${index === 0 ? 'rounded-l-full' : ''} ${
                    index === 5 ? 'rounded-r-full' : ''
                  }`}
                />
              ))}
            </div>
            <div className="flex justify-between text-sm text-gray-600">
              <span>Información General</span>
              <span>Documentación Legal</span>
              <span>Servicios</span>
              <span>Referencias</span>
              <span>Documentos</span>
              <span>Declaraciones</span>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {renderStepContent()}

            <div className="flex justify-between mt-8">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep(prev => prev - 1)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Anterior
                </button>
              )}
              {currentStep < 6 ? (
                <button
                  type="button"
                  onClick={() => setCurrentStep(prev => prev + 1)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors ml-auto"
                >
                  Siguiente
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 ml-auto"
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      Procesando...
                    </>
                  ) : (
                    'Completar Registro'
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}