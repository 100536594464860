import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import AdminDashboard from './pages/AdminDashboard';
import LandingPage from './pages/LandingPage';
import OwnerDashboard from './pages/OwnerDashboard';
import ProviderRegistration from './pages/ProviderRegistration';
import AdminRegistration from './pages/AdminRegistration';
import Auth from './pages/Auth';
import DevBanner from './components/DevBanner';

export default function App() {
  return (
    <AuthProvider>
      <DevBanner />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/admin/registro" element={<AdminRegistration />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/propietarios" element={<OwnerDashboard />} />
        <Route path="/proveedores" element={<ProviderRegistration />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AuthProvider>
  );
}