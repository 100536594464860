import React, { useState } from 'react';
import { Upload, Search, AlertCircle, FileText, DollarSign, Building2, User } from 'lucide-react';

type Payment = {
  id: number;
  resident: string;
  unit: string;
  building: string;
  amount: number;
  dueDate: string;
  status: 'pending' | 'paid' | 'overdue';
  lastNotification?: string;
};

const payments: Payment[] = [
  {
    id: 1,
    resident: 'Juan Pérez',
    unit: '4B',
    building: 'Edificio San Martín',
    amount: 15000,
    dueDate: '2024-03-25',
    status: 'overdue',
    lastNotification: '2024-03-26'
  },
  {
    id: 2,
    resident: 'María García',
    unit: '2A',
    building: 'Torre Libertad',
    amount: 18000,
    dueDate: '2024-04-10',
    status: 'pending'
  }
];

export default function Payments() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS'
    }).format(amount);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const getStatusBadgeClass = (status: Payment['status']) => {
    const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium inline-flex items-center gap-1';
    switch (status) {
      case 'paid':
        return `${baseClasses} bg-green-100 text-green-800`;
      case 'pending':
        return `${baseClasses} bg-yellow-100 text-yellow-800`;
      case 'overdue':
        return `${baseClasses} bg-red-100 text-red-800`;
      default:
        return baseClasses;
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm border p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Gestión de Pagos</h2>
          <div className="flex gap-2">
            <input
              type="file"
              id="expensesFile"
              onChange={handleFileChange}
              accept=".pdf,.txt"
              className="hidden"
            />
            <label
              htmlFor="expensesFile"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors cursor-pointer flex items-center gap-2"
            >
              <Upload className="h-4 w-4" />
              Subir Liquidación
            </label>
          </div>
        </div>

        {selectedFile && (
          <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FileText className="h-5 w-5 text-blue-500" />
              <span className="text-blue-700">{selectedFile.name}</span>
            </div>
            <button 
              onClick={() => setSelectedFile(null)}
              className="text-blue-600 hover:text-blue-700"
            >
              Eliminar
            </button>
          </div>
        )}

        <div className="mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Buscar por residente o unidad..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Residente</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Edificio</th>
                <th className="text-right py-3 px-4 text-sm font-semibold text-gray-600">Monto</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Vencimiento</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Estado</th>
                <th className="text-right py-3 px-4 text-sm font-semibold text-gray-600">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id} className="border-b last:border-b-0 hover:bg-gray-50">
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <User className="h-4 w-4 text-gray-400" />
                      <div>
                        <p className="font-medium">{payment.resident}</p>
                        <p className="text-sm text-gray-500">Unidad {payment.unit}</p>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Building2 className="h-4 w-4 text-blue-500" />
                      <span>{payment.building}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4 text-right">
                    <div className="flex items-center justify-end gap-1">
                      <DollarSign className="h-4 w-4 text-gray-400" />
                      <span className="font-medium">{formatCurrency(payment.amount)}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">{formatDate(payment.dueDate)}</td>
                  <td className="py-3 px-4">
                    <span className={getStatusBadgeClass(payment.status)}>
                      {payment.status === 'paid' && 'Pagado'}
                      {payment.status === 'pending' && 'Pendiente'}
                      {payment.status === 'overdue' && 'Vencido'}
                    </span>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex justify-end">
                      {payment.status === 'overdue' && (
                        <button 
                          className="px-3 py-1.5 text-sm bg-red-50 text-red-600 rounded-lg hover:bg-red-100 transition-colors flex items-center gap-1"
                          title={payment.lastNotification ? `Última notificación: ${formatDate(payment.lastNotification)}` : ''}
                        >
                          <AlertCircle className="h-4 w-4" />
                          Notificar
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}