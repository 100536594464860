import React from 'react';
import { Building2, Users, MoreVertical } from 'lucide-react';

type Consortium = {
  id: number;
  name: string;
  status: 'activo' | 'inactivo' | 'en-revision';
  owners: number;
  address: string;
};

const consortiums: Consortium[] = [
  {
    id: 1,
    name: 'Edificio San Martín',
    status: 'activo',
    owners: 24,
    address: 'San Martín 1234'
  },
  {
    id: 2,
    name: 'Torre Libertad',
    status: 'activo',
    owners: 45,
    address: 'Libertad 567'
  },
  {
    id: 3,
    name: 'Residencial del Sol',
    status: 'en-revision',
    owners: 32,
    address: 'Belgrano 890'
  },
  {
    id: 4,
    name: 'Plaza Central',
    status: 'inactivo',
    owners: 18,
    address: 'Plaza 234'
  }
];

const getStatusBadgeClass = (status: Consortium['status']) => {
  const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium';
  switch (status) {
    case 'activo':
      return `${baseClasses} bg-green-100 text-green-800`;
    case 'inactivo':
      return `${baseClasses} bg-gray-100 text-gray-800`;
    case 'en-revision':
      return `${baseClasses} bg-yellow-100 text-yellow-800`;
    default:
      return baseClasses;
  }
};

export default function ConsortiumTable() {
  return (
    <div className="bg-white rounded-lg shadow-sm border">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Consorcios</h2>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700 transition-colors">
            Nuevo Consorcio
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Nombre del Consorcio</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Estado</th>
                <th className="text-center py-3 px-4 text-sm font-semibold text-gray-600">Propietarios</th>
                <th className="text-right py-3 px-4 text-sm font-semibold text-gray-600">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {consortiums.map((consortium) => (
                <tr key={consortium.id} className="border-b last:border-b-0 hover:bg-gray-50">
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-3">
                      <Building2 className="h-5 w-5 text-blue-500" />
                      <div>
                        <span className="text-sm font-medium text-gray-700 block">{consortium.name}</span>
                        <span className="text-xs text-gray-500">{consortium.address}</span>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <span className={getStatusBadgeClass(consortium.status)}>
                      {consortium.status.charAt(0).toUpperCase() + consortium.status.slice(1)}
                    </span>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center justify-center gap-1">
                      <Users className="h-4 w-4 text-gray-400" />
                      <span className="text-sm text-gray-600">{consortium.owners}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4 text-right">
                    <button className="text-gray-400 hover:text-gray-600">
                      <MoreVertical className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}