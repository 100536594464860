import React, { useState } from 'react';
import { 
  Search, 
  Bell, 
  UserCircle, 
  ChevronDown,
  Calendar,
  CheckCircle2,
  X as XIcon,
  Clock,
  FileText,
  Menu,
  Building2,
  Receipt,
  Scale,
  Book,
  AlertCircle,
  DollarSign,
  Users,
  Loader,
  Home,
  Shield,
  Heart
} from 'lucide-react';
import CommunityFeed from '../components/social/CommunityFeed';
import NeighborDirectory from '../components/social/NeighborDirectory';

export default function OwnerDashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [ownerName] = useState('Juan Pérez');
  const [isCouncilMember] = useState(true);

  const menuItems = [
    { id: 'dashboard', icon: Home, label: 'Inicio' },
    { id: 'social', icon: Heart, label: 'Comunidad' },
    { id: 'tasks', icon: CheckCircle2, label: 'Tareas' },
    { id: 'payments', icon: Receipt, label: 'Pagos' },
    { id: 'assemblies', icon: Users, label: 'Asambleas' },
    { id: 'documents', icon: FileText, label: 'Documentos' }
  ];

  const renderContent = () => {
    switch (activeSection) {
      case 'social':
        return (
          <div className="space-y-6">
            <CommunityFeed />
            <NeighborDirectory />
          </div>
        );
      case 'dashboard':
        return (
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-sm border p-6">
              <h2 className="text-2xl font-semibold mb-4">¡Hola, {ownerName}!</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-blue-50 rounded-lg p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <CheckCircle2 className="h-5 w-5 text-blue-600" />
                    <h3 className="font-medium">Tareas Pendientes</h3>
                  </div>
                  <p className="text-2xl font-semibold">3</p>
                </div>
                <div className="bg-green-50 rounded-lg p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <DollarSign className="h-5 w-5 text-green-600" />
                    <h3 className="font-medium">Pagos Pendientes</h3>
                  </div>
                  <p className="text-2xl font-semibold">$15,000</p>
                </div>
                <div className="bg-purple-50 rounded-lg p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <Calendar className="h-5 w-5 text-purple-600" />
                    <h3 className="font-medium">Próximas Asambleas</h3>
                  </div>
                  <p className="text-2xl font-semibold">2</p>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <aside className={`fixed top-0 left-0 z-40 h-screen transition-transform lg:translate-x-0 ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0 w-64`}>
        <div className="h-full px-3 py-4 overflow-y-auto bg-white border-r">
          <div className="flex items-center justify-between mb-6 px-2">
            <h1 className="text-xl font-bold text-gray-800">Panel del Propietario</h1>
            <button onClick={() => setIsSidebarOpen(false)} className="lg:hidden">
              <XIcon className="h-6 w-6 text-gray-500" />
            </button>
          </div>
          
          <nav className="space-y-1">
            {menuItems.map((item) => {
              const Icon = item.icon;
              return (
                <button
                  key={item.id}
                  onClick={() => {
                    setActiveSection(item.id);
                    setIsSidebarOpen(false);
                  }}
                  className={`w-full flex items-center px-4 py-3 text-sm rounded-lg ${
                    activeSection === item.id
                      ? 'bg-blue-50 text-blue-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <Icon className="h-5 w-5 mr-3" />
                  {item.label}
                </button>
              );
            })}
          </nav>
        </div>
      </aside>

      <div className={`lg:ml-64`}>
        {/* Header */}
        <header className="bg-white border-b px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="lg:hidden mr-3"
              >
                <Menu className="h-6 w-6 text-gray-500" />
              </button>
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                <input
                  type="text"
                  placeholder="Buscar..."
                  className="pl-10 pr-4 py-2 border rounded-lg w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
            
            <div className="flex items-center gap-4">
              <button className="relative">
                <Bell className="h-6 w-6 text-gray-500" />
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  2
                </span>
              </button>
              <div className="flex items-center gap-2">
                <UserCircle className="h-8 w-8 text-gray-400" />
                <div className="flex flex-col items-start">
                  <span className="text-sm font-medium text-gray-700">{ownerName}</span>
                  {isCouncilMember && (
                    <div className="flex items-center gap-1 px-2 py-0.5 bg-purple-100 text-purple-700 rounded-full text-xs font-medium">
                      <Shield className="h-3 w-3" />
                      Miembro del Consejo
                    </div>
                  )}
                </div>
                <ChevronDown className="h-4 w-4 text-gray-500" />
              </div>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <main className="p-6">
          {renderContent()}
        </main>
      </div>
    </div>
  );
}