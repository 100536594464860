import React, { useState } from 'react';
import { Search, Mail, MessageSquare, Building2, Home, Filter } from 'lucide-react';

type Neighbor = {
  id: number;
  name: string;
  unit: string;
  interests: string[];
  skills: string[];
  floor: string;
  joinedDate: string;
};

const sampleNeighbors: Neighbor[] = [
  {
    id: 1,
    name: 'María García',
    unit: '4B',
    floor: '4',
    interests: ['Jardinería', 'Lectura', 'Cocina'],
    skills: ['Profesor de música', 'Primeros auxilios'],
    joinedDate: '2020-05-15'
  },
  {
    id: 2,
    name: 'Carlos Rodríguez',
    unit: '2A',
    floor: '2',
    interests: ['Deportes', 'Fotografía'],
    skills: ['Electricista', 'Carpintería'],
    joinedDate: '2019-03-20'
  },
  {
    id: 3,
    name: 'Ana Martínez',
    unit: '5C',
    floor: '5',
    interests: ['Arte', 'Yoga', 'Voluntariado'],
    skills: ['Diseño gráfico', 'Organización de eventos'],
    joinedDate: '2021-08-10'
  },
  {
    id: 4,
    name: 'Roberto Sánchez',
    unit: '3D',
    floor: '3',
    interests: ['Música', 'Cine', 'Tecnología'],
    skills: ['Programación', 'Reparación de computadoras'],
    joinedDate: '2022-01-15'
  },
  {
    id: 5,
    name: 'Laura Torres',
    unit: '1A',
    floor: '1',
    interests: ['Mascotas', 'Plantas', 'Cocina'],
    skills: ['Veterinaria', 'Jardinería'],
    joinedDate: '2020-11-30'
  },
  {
    id: 6,
    name: 'Miguel Ángel López',
    unit: '6B',
    floor: '6',
    interests: ['Deportes', 'Viajes', 'Fotografía'],
    skills: ['Entrenador personal', 'Fotógrafo'],
    joinedDate: '2021-04-22'
  }
];

export default function NeighborDirectory() {
  const [searchTerm, setSearchTerm] = useState('');
  const [neighbors] = useState(sampleNeighbors);
  const [selectedFloor, setSelectedFloor] = useState('');
  const [selectedInterest, setSelectedInterest] = useState('');
  const [selectedSkill, setSelectedSkill] = useState('');

  // Get unique floors, interests, and skills
  const floors = Array.from(new Set(neighbors.map(n => n.floor))).sort();
  const allInterests = Array.from(new Set(neighbors.flatMap(n => n.interests))).sort();
  const allSkills = Array.from(new Set(neighbors.flatMap(n => n.skills))).sort();

  const filteredNeighbors = neighbors.filter(neighbor => {
    const matchesSearch = 
      neighbor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      neighbor.unit.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesFloor = !selectedFloor || neighbor.floor === selectedFloor;
    const matchesInterest = !selectedInterest || neighbor.interests.includes(selectedInterest);
    const matchesSkill = !selectedSkill || neighbor.skills.includes(selectedSkill);

    return matchesSearch && matchesFloor && matchesInterest && matchesSkill;
  });

  return (
    <div className="bg-white rounded-lg shadow-sm border">
      <div className="p-6">
        <div className="flex flex-col gap-4 mb-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">Directorio de Vecinos</h2>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="text"
                placeholder="Buscar por nombre o unidad..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border rounded-lg w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Advanced Filters */}
          <div className="flex flex-wrap gap-4">
            <select
              value={selectedFloor}
              onChange={(e) => setSelectedFloor(e.target.value)}
              className="px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Todos los pisos</option>
              {floors.map(floor => (
                <option key={floor} value={floor}>Piso {floor}</option>
              ))}
            </select>

            <select
              value={selectedInterest}
              onChange={(e) => setSelectedInterest(e.target.value)}
              className="px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Todos los intereses</option>
              {allInterests.map(interest => (
                <option key={interest} value={interest}>{interest}</option>
              ))}
            </select>

            <select
              value={selectedSkill}
              onChange={(e) => setSelectedSkill(e.target.value)}
              className="px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Todas las habilidades</option>
              {allSkills.map(skill => (
                <option key={skill} value={skill}>{skill}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredNeighbors.map((neighbor) => (
            <div key={neighbor.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between mb-3">
                <div>
                  <h3 className="font-medium">{neighbor.name}</h3>
                  <div className="flex items-center gap-1 text-sm text-gray-500">
                    <Home className="h-4 w-4" />
                    <span>Unidad {neighbor.unit}</span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button className="p-2 text-gray-400 hover:text-blue-600 rounded-lg">
                    <Mail className="h-4 w-4" />
                  </button>
                  <button className="p-2 text-gray-400 hover:text-blue-600 rounded-lg">
                    <MessageSquare className="h-4 w-4" />
                  </button>
                </div>
              </div>

              <div className="space-y-3">
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-1">Intereses</h4>
                  <div className="flex flex-wrap gap-1">
                    {neighbor.interests.map((interest, index) => (
                      <span
                        key={index}
                        className="px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-xs"
                      >
                        {interest}
                      </span>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-1">Habilidades</h4>
                  <div className="flex flex-wrap gap-1">
                    {neighbor.skills.map((skill, index) => (
                      <span
                        key={index}
                        className="px-2 py-1 bg-green-50 text-green-700 rounded-full text-xs"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}