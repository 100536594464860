import React, { useState } from 'react';
import { Calendar as CalendarIcon, Clock, Users, Building2, Video, Plus } from 'lucide-react';

type Event = {
  id: number;
  title: string;
  date: string;
  time: string;
  type: 'maintenance' | 'assembly' | 'meeting';
  building: string;
  participants?: string[];
  isVirtual: boolean;
  meetingUrl?: string;
};

const events: Event[] = [
  {
    id: 1,
    title: 'Mantenimiento Ascensores',
    date: '2024-03-25',
    time: '10:00',
    type: 'maintenance',
    building: 'Edificio San Martín',
    isVirtual: false
  },
  {
    id: 2,
    title: 'Asamblea Ordinaria',
    date: '2024-03-28',
    time: '18:00',
    type: 'assembly',
    building: 'Torre Libertad',
    participants: ['Consejo de Propietarios', 'Administración'],
    isVirtual: true,
    meetingUrl: 'https://meet.google.com/abc-defg-hij'
  }
];

export default function Calendar() {
  const [selectedDate, setSelectedDate] = useState<string>('');

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getEventTypeStyles = (type: Event['type']) => {
    switch (type) {
      case 'maintenance':
        return 'bg-yellow-100 text-yellow-800';
      case 'assembly':
        return 'bg-blue-100 text-blue-800';
      case 'meeting':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm border p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Calendario de Eventos</h2>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2">
            <Plus className="h-4 w-4" />
            Nuevo Evento
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2">
            <div className="space-y-4">
              {events.map((event) => (
                <div key={event.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="font-medium text-lg">{event.title}</h3>
                      <div className="flex items-center gap-4 mt-2">
                        <p className="text-gray-600 flex items-center gap-1">
                          <CalendarIcon className="h-4 w-4" />
                          {formatDate(event.date)}
                        </p>
                        <p className="text-gray-600 flex items-center gap-1">
                          <Clock className="h-4 w-4" />
                          {event.time}
                        </p>
                      </div>
                    </div>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${getEventTypeStyles(event.type)}`}>
                      {event.type === 'maintenance' && 'Mantenimiento'}
                      {event.type === 'assembly' && 'Asamblea'}
                      {event.type === 'meeting' && 'Reunión'}
                    </span>
                  </div>

                  <div className="space-y-3">
                    <p className="flex items-center gap-2 text-gray-600">
                      <Building2 className="h-4 w-4" />
                      {event.building}
                    </p>
                    
                    {event.participants && (
                      <p className="flex items-center gap-2 text-gray-600">
                        <Users className="h-4 w-4" />
                        {event.participants.join(', ')}
                      </p>
                    )}

                    {event.isVirtual && (
                      <div className="flex items-center gap-2">
                        <Video className="h-4 w-4 text-blue-500" />
                        <a
                          href={event.meetingUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-700 hover:underline"
                        >
                          Unirse a la reunión virtual
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="lg:col-span-1">
            <div className="border rounded-lg p-4">
              <h3 className="font-medium mb-4">Filtros</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fecha
                  </label>
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tipo de Evento
                  </label>
                  <div className="space-y-2">
                    {['maintenance', 'assembly', 'meeting'].map((type) => (
                      <label key={type} className="flex items-center gap-2">
                        <input type="checkbox" className="rounded text-blue-600" />
                        <span className="text-sm text-gray-700">
                          {type === 'maintenance' && 'Mantenimiento'}
                          {type === 'assembly' && 'Asamblea'}
                          {type === 'meeting' && 'Reunión'}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}