import { supabase } from '../lib/supabase';
import { getChatCompletion } from './openAIService';
import type { Message, ChatSession } from '../types/chat';
import { v4 as uuidv4 } from 'uuid';

class ChatService {
  async createChatSession(title: string): Promise<ChatSession> {
    const anonymousId = uuidv4();
    const { data, error } = await supabase
      .from('chat_sessions')
      .insert([{ 
        user_id: anonymousId, 
        title 
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async getChatSessions(sessionId: string): Promise<ChatSession[]> {
    const { data, error } = await supabase
      .from('chat_sessions')
      .select('*')
      .eq('id', sessionId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  }

  async getChatMessages(sessionId: string): Promise<Message[]> {
    const { data, error } = await supabase
      .from('chat_messages')
      .select('*')
      .eq('session_id', sessionId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data;
  }

  private async logAnonChat(content: string, role: 'user' | 'assistant') {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const ip = data.ip;

      // Get country from IP
      const geoResponse = await fetch(`https://ipapi.co/${ip}/json/`);
      const geoData = await geoResponse.json();
      const country = geoData.country_name;

      await supabase
        .from('anon_chats')
        .insert([{
          ip,
          country,
          content,
          role
        }]);
    } catch (error) {
      console.error('Error logging anonymous chat:', error);
      // Don't throw the error to avoid disrupting the chat flow
    }
  }

  async sendMessage(content: string, previousMessages: Message[] = []): Promise<string> {
    try {
      // Log user message
      await this.logAnonChat(content, 'user');

      // Format messages for OpenAI
      const messages = previousMessages.map(msg => ({
        role: msg.role as 'user' | 'assistant',
        content: msg.content
      }));
      messages.push({ role: 'user', content });

      // Get AI response
      const aiResponse = await getChatCompletion(messages);
      
      // Log assistant response
      await this.logAnonChat(aiResponse, 'assistant');

      return aiResponse;

    } catch (error) {
      console.error('Chat error:', error);
      throw new Error('Error al procesar tu mensaje. Por favor, intenta nuevamente.');
    }
  }
}

export const chatService = new ChatService();