import React, { useState } from 'react';
import { 
  Building2, 
  Users, 
  Receipt, 
  Calendar as CalendarIcon, 
  Settings as SettingsIcon,
  Menu,
  X,
  LayoutDashboard
} from 'lucide-react';
import HeaderActions from '../components/HeaderActions';
import Settings from '../components/Settings';
import Overview from './admin/Overview';
import Buildings from './admin/Buildings';
import Residents from './admin/Residents';
import Payments from './admin/Payments';
import Calendar from './admin/Calendar';

export default function AdminDashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('overview');

  const menuItems = [
    { id: 'overview', icon: LayoutDashboard, label: 'Vista General' },
    { id: 'buildings', icon: Building2, label: 'Edificios' },
    { id: 'residents', icon: Users, label: 'Residentes' },
    { id: 'payments', icon: Receipt, label: 'Pagos' },
    { id: 'calendar', icon: CalendarIcon, label: 'Calendario' },
    { id: 'settings', icon: SettingsIcon, label: 'Configuración' },
  ];

  const renderContent = () => {
    switch (activeSection) {
      case 'overview':
        return <Overview />;
      case 'buildings':
        return <Buildings />;
      case 'residents':
        return <Residents />;
      case 'payments':
        return <Payments />;
      case 'calendar':
        return <Calendar />;
      case 'settings':
        return <Settings />;
      default:
        return <Overview />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <aside className={`fixed top-0 left-0 z-40 h-screen transition-transform lg:translate-x-0 ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
        <div className="h-full px-3 py-4 overflow-y-auto bg-white border-r w-64">
          <div className="flex items-center justify-between mb-6 px-2">
            <h1 className="text-xl font-bold text-gray-800">Gestión de Consorcios</h1>
            <button onClick={() => setIsSidebarOpen(false)} className="lg:hidden">
              <X className="h-6 w-6 text-gray-500" />
            </button>
          </div>
          
          <nav className="space-y-1">
            {menuItems.map((item) => {
              const Icon = item.icon;
              return (
                <button
                  key={item.id}
                  onClick={() => {
                    setActiveSection(item.id);
                    setIsSidebarOpen(false);
                  }}
                  className={`w-full flex items-center px-4 py-3 text-sm rounded-lg ${
                    activeSection === item.id
                      ? 'bg-blue-50 text-blue-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <Icon className="h-5 w-5 mr-3" />
                  {item.label}
                </button>
              );
            })}
          </nav>
        </div>
      </aside>

      {/* Main Content */}
      <div className="lg:ml-64">
        {/* Header */}
        <header className="bg-white border-b px-4 py-3">
          <div className="flex items-center">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="lg:hidden mr-3"
            >
              <Menu className="h-6 w-6 text-gray-500" />
            </button>
            <HeaderActions />
          </div>
        </header>

        {/* Main Content Area */}
        <main className="p-6">
          {renderContent()}
        </main>
      </div>
    </div>
  );
}