import React from 'react';
import TasksTable from '../../components/TasksTable';
import BudgetsTable from '../../components/BudgetsTable';
import ConsortiumTable from '../../components/ConsortiumTable';
import StatsCards from '../../components/StatsCards';
import VirtualAssembly from '../../components/VirtualAssembly';

export default function Overview() {
  return (
    <div className="space-y-6">
      <div className="mb-6">
        <StatsCards />
      </div>

      <div className="mb-6">
        <ConsortiumTable />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <TasksTable />
        <BudgetsTable />
      </div>

      <div>
        <VirtualAssembly />
      </div>
    </div>
  );
}