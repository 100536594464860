import OpenAI from 'openai';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

if (!apiKey) {
  throw new Error('Missing OpenAI API key');
}

const openai = new OpenAI({
  apiKey,
  dangerouslyAllowBrowser: true
});

const SYSTEM_PROMPT = `Eres un asistente experto en administración de consorcios.
Tu objetivo es ayudar a administradores, propietarios y proveedores con consultas relacionadas a:
- Legislación de consorcios
- Gestión administrativa
- Mejores prácticas
- Documentación necesaria
- Resolución de conflictos
- Mantenimiento de edificios

Responde de manera profesional, clara y concisa, siempre basándote en la normativa vigente y las mejores prácticas del sector.`;

export async function getChatCompletion(messages: Array<{ role: string; content: string }>) {
  try {
    const completion = await openai.chat.completions.create({
      model: "chatgpt-4o-latest",
      messages: [
        { role: 'system', content: SYSTEM_PROMPT },
        ...messages
      ],
      temperature: 0.7,
      max_tokens: 500
    });

    return completion.choices[0]?.message?.content || 
      "Lo siento, no pude procesar tu consulta. Por favor, intenta nuevamente.";
  } catch (error) {
    console.error('OpenAI API error:', error);
    throw new Error('Error al procesar tu consulta. Por favor, intenta nuevamente.');
  }
}