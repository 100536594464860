import React from 'react';
import { FileCheck, FileX, Building2 } from 'lucide-react';

type Budget = {
  id: number;
  provider: string;
  amount: number;
  status: 'aprobado' | 'pendiente' | 'rechazado';
  description: string;
  date: string;
  consortium: {
    id: number;
    name: string;
  };
};

const budgets: Budget[] = [
  {
    id: 1,
    provider: 'Ascensores Modernos S.A.',
    amount: 12500,
    status: 'pendiente',
    description: 'Mantenimiento anual de ascensores',
    date: '2024-03-25',
    consortium: { id: 1, name: 'Edificio San Martín' }
  },
  {
    id: 2,
    provider: 'Seguridad Total',
    amount: 8750,
    status: 'aprobado',
    description: 'Sistema de cámaras de seguridad',
    date: '2024-03-28',
    consortium: { id: 2, name: 'Torre Libertad' }
  },
  {
    id: 3,
    provider: 'Jardines Express',
    amount: 3200,
    status: 'rechazado',
    description: 'Mantenimiento mensual de jardines',
    date: '2024-03-30',
    consortium: { id: 1, name: 'Edificio San Martín' }
  },
  {
    id: 4,
    provider: 'Pinturas del Sur',
    amount: 15800,
    status: 'pendiente',
    description: 'Pintura de fachada',
    date: '2024-04-02',
    consortium: { id: 3, name: 'Residencial del Sol' }
  },
  {
    id: 5,
    provider: 'Plomería Rápida',
    amount: 4500,
    status: 'aprobado',
    description: 'Reparación de cañerías',
    date: '2024-04-05',
    consortium: { id: 2, name: 'Torre Libertad' }
  }
].sort((a, b) => a.consortium.name.localeCompare(b.consortium.name));

const getStatusBadgeClass = (status: Budget['status']) => {
  const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium inline-flex items-center gap-1';
  switch (status) {
    case 'aprobado':
      return `${baseClasses} bg-green-100 text-green-800`;
    case 'pendiente':
      return `${baseClasses} bg-yellow-100 text-yellow-800`;
    case 'rechazado':
      return `${baseClasses} bg-red-100 text-red-800`;
    default:
      return baseClasses;
  }
};

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  }).format(amount);
};

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export default function BudgetsTable() {
  return (
    <div className="bg-white rounded-lg shadow-sm border">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Presupuestos</h2>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700 transition-colors">
            Nuevo Presupuesto
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Consorcio</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Proveedor</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Descripción</th>
                <th className="text-right py-3 px-4 text-sm font-semibold text-gray-600">Monto</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Estado</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Fecha</th>
              </tr>
            </thead>
            <tbody>
              {budgets.map((budget) => (
                <tr key={budget.id} className="border-b last:border-b-0 hover:bg-gray-50">
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Building2 className="h-4 w-4 text-blue-500" />
                      <span className="text-sm font-medium text-gray-700">{budget.consortium.name}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <span className="text-sm font-medium text-gray-700">{budget.provider}</span>
                  </td>
                  <td className="py-3 px-4">
                    <span className="text-sm text-gray-600">{budget.description}</span>
                  </td>
                  <td className="py-3 px-4 text-right">
                    <span className="text-sm font-medium">{formatCurrency(budget.amount)}</span>
                  </td>
                  <td className="py-3 px-4">
                    <span className={getStatusBadgeClass(budget.status)}>
                      {budget.status === 'aprobado' && <FileCheck className="h-3 w-3" />}
                      {budget.status === 'rechazado' && <FileX className="h-3 w-3" />}
                      {budget.status.charAt(0).toUpperCase() + budget.status.slice(1)}
                    </span>
                  </td>
                  <td className="py-3 px-4">
                    <span className="text-sm text-gray-600">{formatDate(budget.date)}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}