import React, { useState } from 'react';
import { Search, Plus, Mail, MessageSquare, Filter, Building2, User, Home } from 'lucide-react';

type Resident = {
  id: number;
  name: string;
  unit: string;
  building: string;
  email: string;
  phone: string;
  hasUnreadMessages: boolean;
};

const residents: Resident[] = [
  {
    id: 1,
    name: 'Juan Pérez',
    unit: '4B',
    building: 'Edificio San Martín',
    email: 'juan.perez@email.com',
    phone: '11-1234-5678',
    hasUnreadMessages: true
  },
  {
    id: 2,
    name: 'María García',
    unit: '2A',
    building: 'Torre Libertad',
    email: 'maria.garcia@email.com',
    phone: '11-8765-4321',
    hasUnreadMessages: false
  }
];

export default function Residents() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBuilding, setSelectedBuilding] = useState('');

  const filteredResidents = residents.filter(resident => {
    const matchesSearch = 
      resident.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.unit.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.building.toLowerCase().includes(searchTerm.toLowerCase());
    
    if (selectedBuilding) {
      return matchesSearch && resident.building === selectedBuilding;
    }
    
    return matchesSearch;
  });

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm border p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Gestión de Residentes</h2>
          <div className="flex gap-2">
            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2">
              <MessageSquare className="h-4 w-4" />
              Mensaje Grupal
            </button>
            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2">
              <Plus className="h-4 w-4" />
              Nuevo Residente
            </button>
          </div>
        </div>

        <div className="flex gap-4 mb-6">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Buscar por nombre, unidad o edificio..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="relative">
            <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <select
              value={selectedBuilding}
              onChange={(e) => setSelectedBuilding(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg appearance-none bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Todos los edificios</option>
              <option value="Edificio San Martín">Edificio San Martín</option>
              <option value="Torre Libertad">Torre Libertad</option>
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Residente</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Unidad</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Edificio</th>
                <th className="text-left py-3 px-4 text-sm font-semibold text-gray-600">Contacto</th>
                <th className="text-right py-3 px-4 text-sm font-semibold text-gray-600">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredResidents.map((resident) => (
                <tr key={resident.id} className="border-b last:border-b-0 hover:bg-gray-50">
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-3">
                      <div className="bg-gray-100 rounded-full p-2">
                        <User className="h-5 w-5 text-gray-600" />
                      </div>
                      <span className="font-medium">{resident.name}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Home className="h-4 w-4 text-gray-400" />
                      <span>{resident.unit}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Building2 className="h-4 w-4 text-blue-500" />
                      <span>{resident.building}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <div className="space-y-1">
                      <p className="text-sm text-gray-600">{resident.email}</p>
                      <p className="text-sm text-gray-600">{resident.phone}</p>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex justify-end gap-2">
                      <button 
                        className={`p-2 rounded-lg ${
                          resident.hasUnreadMessages 
                            ? 'bg-blue-50 text-blue-600' 
                            : 'text-gray-400 hover:text-gray-600'
                        }`}
                      >
                        <MessageSquare className="h-4 w-4" />
                      </button>
                      <button className="p-2 rounded-lg text-gray-400 hover:text-gray-600">
                        <Mail className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}